import React, { Component } from 'react'
import "./category-information.css";

class CategoryInformation extends Component{
    constructor(props) {
        super(props);
      }    
    
      componentDidMount(){
        var text = document.getElementById("productCategoryInformation");
        text.style.WebkitLineClamp="2";
    }
render(){
  return(
    <div className="productCategoryInformationContainer">
<p
className="productCategoryInformationText"
style={{
    overflow:'hidden',
      display:'-webkit-box',
      WebkitBoxOrient:'vertical',
      fontFamily:'open sans',
      marginBottom:'6px'
}}
    id="productCategoryInformation">{this.props.information}</p>
  <p
  className="categoryShowMore"
  id="productCategoryShowMore"
  onClick={(e) => ShowMore()}
  style={{
   paddingTop:'0px',
   fontFamily:'open sans',
   fontSize:'14px',
   color:'#fe5f55',
   cursor:'pointer'
  }}>See More</p>
    </div>
  );
}
}

function ShowMore(){
    var text = document.getElementById("productCategoryInformation");
    var showMore = document.getElementById("productCategoryShowMore");
    text.style.display = "inline-block";
    showMore.style.display="none";
 }

 
export default CategoryInformation