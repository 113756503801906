import React, { Component } from "react";
import "./category-type.css";
import arrow from '../images/arrow.png'
import { Link } from 'gatsby';

class CategoryType extends Component {

  constructor(props) {
    super(props);
  }    

  componentDidMount(){
    console.log(this.props.selectedCategory);
    var block = document.getElementById(this.props.selectedCategory);
    if(block != null){
      block.style.color = "#FFFFFF";
    block.style.background = "#23233B";
    }
  }

  render() {
    return (
      <div className="roomsAndFilterSelectionLeft">
        <div 
        style={{
    paddingRight:'1.50vw',
    fontFamily:'Hind Guntur',
    marginTop: '16px',
    fontSize:'calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)))'
        }}>Room</div>

<div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
      <SpaceTypeView name="Living Room" link="living-room-designs"/>
   

      <SpaceTypeView name="Bedroom" link="bedroom-designs"/>
     

      <SpaceTypeView name="Kitchen" link="kitchen-designs"/>
      

       <SpaceTypeView name="Dining Room" link="dining-room-designs"/>
       </div>
      </div>

    );
  }
}

function SpaceTypeView(props){
  return(
  <Link
  style={{
    textDecoration: 'none',
    color:'#000000'
  }}
  to={props.link}>

  <div id={props.name} className="selectionCategoryBlock">
    <div> {props.name}</div>
    </div>
    </Link>
  );

}

export default CategoryType;
