import PropTypes from 'prop-types'
import React, { Component } from "react"
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import Img from 'gatsby-image';

var imgageSrc= ""
class Product extends Component {
  constructor(props){
    super(props)
    
    this.state = {
        imageSrc : props.frontmatter.images[0].image.childImageSharp.fluid,
    }
  }

  componentDidMount(){
    this.setState({
        imageSrc : this.props.frontmatter.images[0].image.childImageSharp.fluid,
    })
  }

  _handleStyleChange = (newImageSrc) => {
    this.setState({imageSrc : newImageSrc});
  }

  styleImageClick(index, image) {
    var styleImage = document.getElementsByClassName(this.props.frontmatter.title);
    for (var i = 0; i < styleImage.length; i++) {
      styleImage[i].style.border = "none";
    }
    styleImage[index].style.border = "2px solid #fe5f55";
    this._handleStyleChange(image.image.childImageSharp.fluid);
  }

  render(){
    return(
      <div className="productDesignContainer" id={this.props.id}
        style={{
        display:IsVisible(this.props.sizeFilters, this.props.themeFilters,  this.props.frontmatter) ? "block" : "none",
        flex:'0 1 auto' }}>

          <Link style={{
            textDecoration:'none',
            color:'#000000'}} to={this.props.frontmatter.slug}>
              <Img fluid={this.state.imageSrc} id={this.props.frontmatter.title}fadeIn={false}/>
          </Link>

          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.5vh' }}>
             
              <Link className="productListItemTitleLink" to={this.props.frontmatter.slug}>
                {this.props.frontmatter.title}
              </Link>

              <div style={{
                display:'flex',
                justifyContent: 'flex-end' }}>
                  {this.props.frontmatter.images.map((image, index) => (
            
                    <div onClick={e => this.styleImageClick(index, image)} className={this.props.frontmatter.title}
                      style={{
                        border: index == 0 ? '2px solid #fe5f55' : 'none',
                        borderRadius:'4px',
                        padding:'2px',
                        marginLeft:'15px',
                        marginBottom:'0px',
                      }}>
                        <Img fluid={image.image.childImageSharp.fluid} style={{width:'24px', height:'24px'}}/>
                    </div>
                  ))}
              </div>
          </div>

          <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap' }}>
                {this.props.frontmatter.sizes.map(size => (
                  <p key={size.id}
                  className="productListItemSizeText">
                      {size}
                    </p>
                ))}
          </div>
      </div>
    )
  }
}
  
function IsVisible(sizeFilters, themeFilters, frontmatter){
var isFound = false;
var count = 0;
var noThemesSelected = true;
var noSizesSelected = true;

sizeFilters.forEach(element => {
if(element.value){
  noSizesSelected = false;
}
});

themeFilters.forEach(element => {
if(element.value){
  noThemesSelected = false;
}
});

if(noThemesSelected && noSizesSelected){
return true;
}

else if(noSizesSelected){
themeFilters.forEach(filter => {
  if(filter.label == frontmatter.theme && filter.value){
    isFound = true;
  }
});
}

else if(noThemesSelected){
frontmatter.sizes.forEach(size => {
  sizeFilters.forEach(filter => {
      if(filter.label == size && filter.value){
        console.log("I'm the label")
        console.log(filter.label)
        isFound = true;
      }
  });
});
}

else{
console.log("I'm in the zone")
frontmatter.sizes.forEach(size => {
  sizeFilters.forEach(filter => {
      if(filter.value && size == filter.label){
      themeFilters.forEach(filter => {
        if(filter.value && frontmatter.theme == filter.label){
          isFound = true;
        }
      })
      }
  });

});
}

console.log("Found:" + isFound)
return isFound;
}

  export default Product

  export const query = graphql`
    fragment Product on MarkdownRemark {
        frontmatter {
          category
            slug
            title
            theme
            sizes
            images{
                image{
                  childImageSharp{
                    fluid(maxWidth : 720){
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                  publicURL
                }
             }
        }
    }
  `