import React, { Component } from 'react'
import "./size-chart.css";

var smallSizesList = ["Largest wall of room is between 8 feet - 11.9 feet"];
var mediumSizesList = ["Largest wall of room is between 12 feet - 14.9 feet"];
var largeSizesList = ["Largest wall of room is above 15 feet"];
class SizeChart extends Component{
render(){
    return(
        <div id="sizeChartModel" className="sizeChartModel" onClick={() =>  this.props.handleClose()}>
        <div className="sizeChartModel-content">
          <h3 className="sizeChartDialogHeading">Room Size Chart (in fts)</h3>
          <div className="separator"></div>
          <div className="sizeValuesContainer">
         
            <div className="sizeValuesInnerContainer">
              <div style={{
                  display:'flex'
              }}>
              <p className="sizeValueHeaderText">Small :</p>
              <p className="sizeValueHeaderMeasurementText">(8 feet - 11.9 feet)</p>

            </div>

                {smallSizesList.map(((size) => (
                   <p className="sizeValueText">{size}</p>
                )))}
          </div>

          <div className="sizeValuesInnerContainer">
              
            <div style={{
                  display:'flex'
              }}>
              <p className="sizeValueHeaderText">Medium :</p>
              <p className="sizeValueHeaderMeasurementText">(12 feet - 14.9 feet)</p>

            </div>
              {mediumSizesList.map(((size) => (
                   <p className="sizeValueText">{size}</p>
                )))}
          </div>

          <div className="sizeValuesInnerContainer">
          <div style={{
                  display:'flex'
              }}>
              <p className="sizeValueHeaderText">Large :</p>
              <p className="sizeValueHeaderMeasurementText">(above 15 feet)</p>

            </div>
              {largeSizesList.map(((size) => (
                   <p className="sizeValueText">{size}</p>
                )))}
          </div>

          </div>
          <h6 className="sizeChartDialogInfo">Example : If room size is  <b><i>8ft * 12ft</i></b>  then it is <b><i>medium</i></b> sized room.</h6>

        </div>
      </div>
    );
}
}

export default SizeChart;