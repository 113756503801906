import PropTypes from 'prop-types'
import React, { Component } from 'react'
import "./banner.css"
import Img from 'gatsby-image'

const Banner = ({ image, title, information }) => (
    <div className="banner">
    <Img fluid={image} style={{minHeight: '100px'}}/>
    <div className="bannerText">
    <text className="designType">{title}</text><br/><br/>
    <text className="designTypeInfo">{information}</text>
    </div>
    </div>
)

export default Banner

