import React, { Component } from "react";
import "./multiselect.css";
import { string } from "prop-types";

var isSelected = false;
class MultiSelect extends Component{
    constructor(props){
        super(props)
        this.state={
            isSelected,
        }
        
    }

    componentDidMount(){
        this.setState({
            isSelected: false,
        });
    }


    handleLangChange = (e, index, list) => {
        e.stopPropagation();
        this.setState({isSelected: !this.state.isSelected});
    list[index].value =  !list[index].value;
    }

    submitChange = (list) => {
            this.props.onSelectItem(list);   
        }

        clearAll = (list) => {

            list.forEach(element => {
                element.value = false;
            });
            this.props.onSelectItem(list);   
        }

    handleOpen = (isOpen) => {
        isOpen = !isOpen;
           console.log("I'm the culprit");
           console.log(isOpen);
            this.props.onSelectBlock(isOpen);   
        }

        getPlaceholderText = (placeholder, list) => {
            var value = "";
            var count = 0;
            var firstSelection;
            list.forEach(element => {
                 if(element.value){
                     if(value == ""){
                        value = element.label;
                        firstSelection = element.label;
                     }
                     else{
                        count += 1;
                         value = firstSelection + " + " + count.toString();
                     }
                 }
             });
             if(value == ""){
                value = placeholder
             }
             return(
                 <div>{value}</div>
             )
        }

        setStyle = (list) => {
            var isFound = false;
            list.forEach(element => {
                if(element.value){
                    isFound = true;
                }
            });
            return isFound;
        }
    render(){
        return(
           <div className="multiSelectContainer">
               <button 
               className="multiSelectSelectedListContainer" id= "multiSelectSelectedListContainer"
               style={{
                   background: this.setStyle(this.props.list)? "#23233B" : "#ffffff",
                   border : this.setStyle(this.props.list)? "1px solid #23233B" : "1px solid #484848",
                   borderRadius:'2px',
                   color: this.setStyle(this.props.list)? "#ffffff" : "#484848",
               }}
               onClick={() => this.handleOpen(this.props.isOpen)}>
               {this.getPlaceholderText(this.props.placeholder, this.props.list)}</button>
               <div className="multiSelectListContainer" style={{display : this.props.isOpen ? 'flex' : 'none'}} onClick={() => this.handleLangChange(this.props.isOpen)}>
               {this.props.list.map((item, index) => (
                   <div onClick={(e) => this.handleLangChange(e, index, this.props.list)} className="multiSelectListItem">
                      <div className="checkboxContainer" style={{background: item.value ? '#23233B' : '#ffffff'}}>
                      <div style={{display: item.value ? 'block' : 'none'}}>&#10003;</div>
                      </div>
                      <div style={{
                         marginTop:'4px',
                         marginLeft:'12px',
                          fontFamily:'Hind Guntur',
                          color:'#484848',
                          fontSize:'16px',
                          fontWeight:'400',
                          cursor:'pointer',

                      }}>{item.label}</div>

                   </div>
   ))}
   <div className="multiselectFooter">
       <p className="multiselectClear" onClick={() => this.clearAll(this.props.list)}>Clear</p>
       <p className="multiSelectApply" onClick={() => this.submitChange(this.props.list)}>Apply</p>
   </div>
               </div>
                
           </div>
        )
    }
}
export default MultiSelect