import React, { Component } from 'react'
import './browse-designs-seo-text.css'

class SeoText extends Component {
    constructor(props){
        super(props);
    }   

    render(){
        return(
            <div className="seoTextContainer">
                <h1 className="seoTextHeading1">{this.props.data.heading1}</h1>
                <p className="seoTextDescription">{this.props.data.description1}</p>

                <h2 className="seoTextHeading2">{this.props.data.heading2}</h2>
                <p className="seoTextDescription">{this.props.data.description2}</p>

                <h3 className="seoTextHeading3">{this.props.data.heading3}</h3>
                <dl type ="a">
                    {
                        this.props.data.types.map(type => {
                            var hasContent = type.description != null;
                            return (
                                <>
                                    <dt className="seoTextTypeTitle" 
                                        style={{textDecoration: hasContent ? 'underline' : 'none'}}>
                                            {  type.title + (hasContent ? ":" : "") }
                                    </dt>
                                    <dd className="seoTextTypeDescription">{type.description}</dd>
                                </>
                            ); 
                        })
                    }
                </dl>
            </div> 
        )
    }
}

export default SeoText;
